import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Link, Stack } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function TermsPage() {
  return (
    <React.Fragment>
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom align="center">
            Contact
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
            <Card sx={{ minWidth: "30%" }}>
              <CardContent>
                <h3>E-Mail</h3>
                <Typography>
                  Need some help? Drop me an email at{" "}
                  <Link href="mailto:louis@eforge.us">louis@eforge.us</Link>.
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ minWidth: "30%" }}>
              <CardContent>
                <h3>Make a ticket</h3>
                <Typography>
                  Found a bug? You can send us an email at this address to
                  automatically create a bug ticket.{" "}
                  <Link href="mailto:contact-project+eforgewebsites-eforge-us-63169713-issue-@incoming.gitlab.com">
                    contact-project+eforgewebsites-eforge-us-63169713-issue-@incoming.gitlab.com
                  </Link>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ minWidth: "30%" }}>
              <CardContent>
                <h3>Social Media</h3>
                If you just want to communicate, find me on the socials.
                <Stack direction="row" marginTop={1}>
                  <Link
                    href="https://www.facebook.com/louisbustin"
                    target="_blank"
                    rel="noreferrer"
                    marginRight={1}
                  >
                    <FacebookIcon />
                  </Link>
                  <Link
                    href="https://www.instagram.com/purveyorofawesome/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon />
                  </Link>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default TermsPage;
