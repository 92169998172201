import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Footer from "./components/Footer";
import AppTheme from "../shared-theme/AppTheme";
import { Outlet } from "react-router-dom";
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NormalLink from "../NormalLink";

export default function Blog(props: { disableCustomTheme?: boolean }) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />

      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: "flex", flexDirection: "column", my: 5, gap: 4 }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <div>
            <Typography variant="h1" gutterBottom>
              <NormalLink to="/">eForge.us</NormalLink>
            </Typography>
            <Typography>Powering modern websites</Typography>
          </div>
        </Box>
        <Outlet />
      </Container>
      <Footer />
    </AppTheme>
  );
}
