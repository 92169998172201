import { Link } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

const NormalLink = (
  props: PropsWithChildren<{
    to: string;
    color?: string;
    variant?: Variant;
  }>
) => {
  const navigate = useNavigate();
  if (props.to.startsWith("http")) {
    return (
      <Link href={props.to} color={props.color} variant={props.variant}>
        {props.children}
      </Link>
    );
  }
  return (
    <Link
      href={props.to}
      onClick={(event) => {
        event.preventDefault();
        navigate(props.to);
      }}
      color={props.color}
      variant={props.variant}
    >
      {props.children}
    </Link>
  );
};

export default NormalLink;
