import Blog from "./components/blog/Blog";
import MainContent from "./components/blog/components/MainContent";
import ContactPage from "./ContactPage";
import ErrorBoundary from "./ErrorBoundary";
import PrivacyPage from "./PrivacyPage";
import TermsPage from "./TermsPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    element: <Blog />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <MainContent />,
      },
      {
        path: "/privacy",
        element: <PrivacyPage />,
      },
      {
        path: "/terms",
        element: <TermsPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
