import { CssBaseline, Container, Box, Typography } from "@mui/material";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import Footer from "./components/blog/components/Footer";
import NormalLink from "./components/NormalLink";
import AppTheme from "./components/shared-theme/AppTheme";

const ErrorBoundary = () => {
  const error = useRouteError();

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />

      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: "flex", flexDirection: "column", my: 5, gap: 4 }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <div>
            <Typography variant="h1" gutterBottom>
              <NormalLink to="/">eForge.us</NormalLink>
            </Typography>
            <Typography>Powering modern websites</Typography>
          </div>
        </Box>
        {isRouteErrorResponse(error) ? (
          <Container>
            <h1>
              {error.status} - {error.statusText}
            </h1>
            <h2>Oh No, something bad happened! Don't panic!</h2>
          </Container>
        ) : (
          "Unknown error message"
        )}
      </Container>
      <Footer />
    </AppTheme>
  );
};

export default ErrorBoundary;
