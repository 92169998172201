import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Facebook, Instagram } from "@mui/icons-material";
import NormalLink from "../../NormalLink";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"Copyright © "}
      <NormalLink color="text.secondary" to="https://eforge.us/">
        eForge.us
      </NormalLink>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <React.Fragment>
      <Divider />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 4, sm: 2 },
          py: { xs: 4, sm: 0 },
          textAlign: { sm: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: { xs: 4, sm: 4 },
            pb: { sm: 2 },
            width: "100%",
          }}
        >
          <div>
            <NormalLink color="text.secondary" variant="body2" to="/privacy">
              Privacy Policy
            </NormalLink>
            <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <NormalLink color="text.secondary" variant="body2" to="/terms">
              Terms of Service
            </NormalLink>
            <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <NormalLink color="text.secondary" variant="body2" to="/contact">
              Contact
            </NormalLink>
            <Copyright />
          </div>
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{ justifyContent: "left", color: "text.secondary" }}
          >
            <IconButton
              color="inherit"
              size="small"
              href="https://gitlab.com/eforgewebsites"
              aria-label="Git Lab"
              sx={{ alignSelf: "center" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m21.94 13.11l-1.05-3.22c0-.03-.01-.06-.02-.09l-2.11-6.48a.86.86 0 0 0-.8-.57c-.36 0-.68.25-.79.58l-2 6.17H8.84L6.83 3.33a.85.85 0 0 0-.79-.58c-.37 0-.69.25-.8.58L3.13 9.82v.01l-1.07 3.28c-.16.5.01 1.04.44 1.34l9.22 6.71c.17.12.39.12.56-.01l9.22-6.7c.43-.3.6-.84.44-1.34M8.15 10.45l2.57 7.91l-6.17-7.91m8.73 7.92l2.47-7.59l.1-.33h3.61l-5.59 7.16m4.1-13.67l1.81 5.56h-3.62m-1.3.95l-1.79 5.51L12 19.24l-2.86-8.79M6.03 3.94L7.84 9.5H4.23m-1.18 4.19c-.09-.07-.13-.19-.09-.29l.79-2.43l5.82 7.45m11.38-4.73l-6.51 4.73l.02-.03l5.79-7.42l.79 2.43c.04.1 0 .22-.09.29"
                ></path>
              </svg>
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://www.facebook.com/louisbustin/"
              aria-label="Facebook"
              sx={{ alignSelf: "center" }}
            >
              <Facebook />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://www.instagram.com/purveyorofawesome/"
              aria-label="Instagram"
              sx={{ alignSelf: "center" }}
            >
              <Instagram />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}
